import React from "react";

export default class Join extends React.Component {
    steps = [
        {
            title: "Choose Your Sector",
            blurb: "Here at Pierre Elliott Trudeau High School, our SHSM sector offers Arts and Culture, Business, Health and Wellness, and ICT!"
        },
        {
            title: "Research",
            blurb: "Once you’ve chosen the sector you would like to pursue, take a look through the page on our website! There, you’ll find what the sector is about and its requirements!"
        },
        {
            title: "Guidance appointment",
            blurb: "Next, book an appointment with our wonderful guidance department through TeachAssist. They will be able to provide you with support throughout your SHSM journey!"
        },
        {
            title:
                <a className="link text-red-500 hover:text-yellow-400"
                   href="https://docs.google.com/forms/d/e/1FAIpQLSfX3Tv6l8BBjmcIbyiZbJrMA61xkZ5t4L4l7jibrStEUcbkWg/viewform">
                    Google Form
                </a>,
            blurb:
                <iframe title={"Google Form Signup"} className="rounded-3xl p-0 w-full my-4"
                        height={400}
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfX3Tv6l8BBjmcIbyiZbJrMA61xkZ5t4L4l7jibrStEUcbkWg/viewform?embedded=true"
                        children={"Loading..."}
                />
        },
        {
            title: "Confirmation",
            blurb:
                <>
                    Once you’ve received email confirmation, your SHSM
                    journey has started! Visit our Instagram,
                    <a className="text-blue-500 hover:text-yellow-400"
                       href="https://classroom.google.com/c/NDMyOTgwNjcw?cjc=bb7sirw"> Google
                        Classroom
                    </a>
                    (bb7sirw), as well as the YRDSB SHSM Website (use
                    your GAPPS account) to start earning certifications!
                </>
        }
    ]

    render() {
        return (
            <section id={"join_section"} className={"lg:px-40 px-5"}>
                <h1 className={"lg:mx-20 my-5 text-center lg:text-left font-bold text-5xl"}>JOIN <b
                    className="text-red-600"> SHSM</b></h1>
                {this.props.blurb}
                <div className="lg:px-32 px-8 lg:py-14 py-8 my-10 bg-[#1b1f20] rounded-3xl">
                    <h2 className={"text-4xl font-extrabold"}>Steps</h2>
                    <p>
                        To make the process a bit easier, we have organized 5
                        easy steps.
                    </p>
                    {
                        this.steps.map((step, i) => {
                            return (
                                <div key={"step_" + i}
                                     className={`bg-[#181a1b] lg:px-12 px-8 py-8 rounded-3xl my-8 relative lg:max-w-[60%] ${i % 2 === 1 ? "mr-0 ml-auto" : "ml-0"}`}>
                                    <p className={`rounded-full bg-red-500 w-10 h-10 items-center flex text-2xl font-bold absolute justify-center -top-4 ${i % 2 === 1 ? '-right-4' : '-left-4'}`}>{i + 1}</p>
                                    <h3 className="text-white text-3xl font-bold">{step.title}</h3>
                                    <p>{step.blurb}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        );
    }
}