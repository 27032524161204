import React from "react";

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe: false
        }
    }

    componentDidMount() {
        this.setState({iframe: true})
    }

    render() {
        return (
            <div className={"w-full lg:px-40 px-5 my-5"}>
                {
                    this.state.iframe &&
                    <iframe src="https://embedsocial.com/api/pro_hashtag/2ff74e46ba7c178c2e5f2ff441e20415700d56ca/"
                            id="embedIFrame_2ff74e46ba7c178c2e5f2ff441e20415700d56ca865c37"
                            className="embedsocial-hashtag-iframe w-full border-0 h-[90vh] bg-white rounded-xl"
                            title="EmbedSocial Universal Widget"
                            allowFullScreen={true}
                    />
                }
            </div>
        );
    }
}