import React from "react";

export default class Sectors extends React.Component {
    state = {
        sector: this.props.child,
        creditRequirements: [
            {
                title: "Major Credits (Minimum 4)",
                children:
                    <ul className={"list-disc my-3 px-3"}>
                        <li>At least one credit at the Grade 11 level</li>
                        <li>At least one credit at the Grade 12 level</li>
                        <li>Many Dual Credit Courses offered through YRDSB's Dual Credit program, also count in this
                            category
                        </li>
                        <li>Students who take 4 credit Co-op may count one credit towards the Major Credits or
                            Related Credits section
                        </li>
                    </ul>,
                list: this.props.child.majorCredits
            },
            {
                title: "English (Minimum 1)",
                list: this.props.child.englishCredits
            },
            {
                title: "Related Math Credits (Minimum 2)",
                list: this.props.child.relatedMathCredits
            },
            {
                title: "Related Credits (Minimum 1)",
                list: this.props.child.relatedCredits
            }
        ],
        sectorRequirements: [
            {
                title: "Compulsory Certifications (Minimum 3)",
                list: this.props.child.compulsoryCertifications
            },
            {
                title: "Elective Certifications (Minimum 3)",
                list: this.props.child.electiveCertifications
            },
            {
                title: "Reach Ahead (Minimum 1)",
                list: this.props.child.reachAheadandExperientialLearning
            },
            {
                title: "Experiential Learning Field Trips (Minimum 1)",
                list: this.props.child.reachAheadandExperientialLearning
            },
            {
                title: "Sector Partnered Event [SPE] (1 out of 3)",
                list: this.props.child.sectorPartneredEvents
            },
            {
                title: "Co-op Placements",
                list: this.props.child.coopPlacements
            }
        ]
    }

    render() {
        return (
            <main className="lg:px-40 px-8 lg:py-10 py-4">
                <h2>{this.props.child.title}</h2>
                <p>{this.props.child.introduction}</p>

                {this.props.blurb}

                <h3 className={"font-bold my-5 text-4xl"}>Credit Requirements</h3>
                {
                    this.state.creditRequirements.map((requirement, i) => {
                        return (
                            requirement.list != null &&
                            <div className="bg-[#1b1f20] my-5 rounded-3xl lg:px-16 p-7"
                                 key={"credit_requirements_" + i}>
                                <h4 className={"text-3xl my-3 font-bold"}>{requirement.title}</h4>
                                {requirement.children}
                                <div className="grid lg:grid-cols-3 justify-between">
                                    {
                                        requirement.list.map((credit, i) => {
                                            return (
                                                <p className={"py-2 flex items-center"}
                                                   key={"credit_requirements_" + i}>
                                                    <input type="checkbox" defaultValue={credit} id={credit}
                                                           className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 px-1"/>
                                                    <label className="text-white dark:text-gray-300 px-2 text-md"
                                                           htmlFor={credit}>{credit}</label>
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                <h3 className={"font-bold text-4xl"}>Sector Requirements</h3>
                {
                    this.state.sectorRequirements.map((requirement, i) => {
                        return (
                            <div className={"bg-[#1b1f20] my-5 rounded-3xl px-16 py-7"}
                                 key={"sector_requirements_" + i}>
                                <h4 className={"text-3xl my-3 font-bold"}>{requirement.title}</h4>
                                <ul className={"grid lg:grid-cols-3 justify-between list-disc text-lg dark:text-gray-300"}>
                                    {
                                        requirement.list.map((certification, i) => {
                                            return (
                                                <li className={"py-1"}
                                                    key={"certification_requirements_" + i}>{certification}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </main>
        );
    }
}