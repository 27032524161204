const Data = [
    {
        name: "ArtsandCultures",
        title: "Arts and Cultures",
        introduction: "Wow! An artistic individual, you are! Our Arts and Culture sector is magnificent (just like your art skills), so I can guarantee you will have an awesome time!",
        active: true,
        description: "Are you an avid creator? Are you an individual who enjoys visual arts, literary arts, or the performing arts? If so, Arts and Culture is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of artistic experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
        insights: [
            "Develop specialized skills through workshops and events with guest speakers",
            "Participate in a multitude of opportunities and network within the business sector",
            "Gain important skills on the job through cooperative education placements"
        ],
        majorCredits: [
            "ADA3O/3M/4E/4M (Drama)",
            "AMS3O/3M/4M (Strings)",
            "AVI3O/3M/4E/4M (Visual Arts)",
            "HSP3C/4U (Anthro / Psych / Soc)",
            "TDJ4M1 (Tech Design)",
            "AMI3M/4M (Band)",
            "ASM3O/3M/4E/4M (Media Arts)",
            "HFC3M (Food and Culture)",
            "HHS4C/U (Families in Canada)",
            "AMR3M/4M (Repertoire)",
            "ATC3O/3M/4E/4M (Dance)",
            "HNC3C/HNB4M (Fashion)",
            "HSE4M (Equity & Social Justice)",
            "AMG3O (Guitar)",
            "AMV3M/4M (Vocal Music)",
            "AWT3O (Experimental Art)",
            "EWC4U (Writer’s Craft)",
            "ADD3O/3M/4M (Drama Production)",
            "AMT3O/4M (Musical Theatre)",
            "AWQ3M/4M (Digital Photography)",
            "HZB3M/HZT4U (Philosophy)",
            "AEA3O/4O (Exploring & Creating)",
            "AMU3M/4E (Music)",
            "AWD4M (Visual Design)",
            "TGJ3M/4M (Communication Tech)"
        ],
        englishCredits: [
            "ENG3C/E/U (English 11)",
            "ENG4C/E/U (English 12)",
            "OLC4O (Literacy)"
        ],
        relatedCredits: [
            "BAI3E (Accounting Essentials)",
            "CGG3O (Travel & Tourism)",
            "BOH4M (Business Leadership)",
            "GLE4O/GLS4O (Learning Strategies)",
            "BAF3M/BAT4M (Accounting)",
            "CHA3U (American History)",
            "MEL3E/4E (Mathematics)",
            "HSB4U (Challenge & Change)",
            "BTA3O (Info Communications Tech)",
            "BBB4M (International Business)",
            "CPW4U (CDN & International Politics)",
            "BDI3C / BDP3O / BDV4C (Entrepreneurship)",
            "CLU3M (Canadian Law)",
            "AWT3O (Experimental Art)",
            "FSF4U (French)",
            "BMI3C (Marketing)",
            "ICS3C/3U/4U (Computer Science)",
            "CLN4C/U (CDN & International Law)",
            "BAN4E (Accounting: Small Business)",
            "CHW3M (World History)",
            "CGW4U (Canadian and World Studies)",
            "OLC4O (Literacy)"
        ],
        compulsoryCertifications: [
            "Standard First Aid & CPR/AED Level C",
            "WHMIS",
            "Customer Service"
        ],
        electiveCertifications: [
            "Advanced Training in a Technique",
            "Customer Service",
            "Infection Control",
            "Wrapping and Taping",
            "Advanced Training in an Art Form",
            "Leadership Skills",
            "Stage Combat",
            "Recording Equipment",
            "Audition Preparation",
            "Portfolio Development",
            "Software"
        ],
        reachAheadandExperientialLearning: [
            "Glass Masks Studio",
            "College/University Tours",
            "Industry Guest Speakers",
            "Special Effects Makeup",
            "Film/Photography Workshops",
            "SEARS",
            "OCAD/CMU College",
            "Mirvish Theatre"
        ],
        sectorPartneredEvents: [
            "Coding",
            "ICE Training",
            "Mathematical Literacy"
        ],
        coopPlacements: [
            "Graphic/Web Design",
            "Video Production",
            "Drama Teaching Assistant",
            "Costumer/Fashion Designer",
            "Flower Assignment",
            "Gallery Curator",
            "Photo Editor",
            "Marketing Assistant",
            "Social Media Assistant",
            "Design Assistant",
            "Art Class Instructor",
            "Interior Design Assistant",
            "Theatre Technician",
            "Interior/Industrial Designer"
        ],
    },
    {
        name: "Business",
        title: "Business",
        introduction: "Hi! Our Business sector is super cool, with a multitude of different events, each appealing to everyone! So what are you waiting for? Sign up now! :)\n",
        description: "Do you enjoy understanding how money works? How businesses are created, run, and managed? If so, Business is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of business-y experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
        insights: [
            "Gain insight on a variety of business-related career paths through engaging activities",
            "Develop skills not offered at school that can be used in real-world situations",
            "Connect with entrepreneurs and industry leaders in a variety of niches"
        ],
        majorCredits: [
            "BAF3M/BAT4M (Accounting)",
            "BTA3O (ICT: The Digital Environment)",
            "BOH4M (Business Leadership)",
            "CGW4U (World Issues)",
            "IDC3O (Sports, Entertainment & Fashion Marketing)",
            "BAI3E (Accounting Essentials)",
            "BAN4E (Accounting for SM Business)",
            "CIE3M (Economics)",
            "CLU3M (Law)",
            "IDC4O (Hospitality Marketing & Management)",
            "BDI3C/BDP3O (Entrepreneurship)",
            "BBB4M (International Business)",
            "CIA4U/CIC4E (Economics)",
            "CLN4C/4U (Law)",
            "IDC4U (Capital Markets)",
            "BMI3C/BMX3E (Marketing)",
            "BDV4C (Entrepreneurship)",
            "CGG3O (Travel & Tourism)",
            "ICS3U/4U (Computer Science)",
            "TGJ3M/4M (Communication Tech)"
        ],
        englishCredits: [
            "ENG3C/E/U (English 11)",
            "ENG4C/E/U (English 12)",
            "OLC4O (Literacy)"
        ],
        relatedMathCredits: [
            "MBF3C (Foundations for College Mathematics)",
            "MCT4C (Mathematics for College Technology)",
            "MCF3M (Functions and Applications)",
            "MDM4U (Data Management)",
            "MCR3U (Functions)",
            "MEL3E/4E (Mathematics for Work and Everyday Life)",
            "MAP4C (Foundations for College Mathematics)",
            "MHF4U (Advanced Functions)"
        ],
        compulsoryCertifications: [
            "Standard First Aid & CPR/AED Level C",
            "WHMIS",
            "Customer Service"
        ],
        electiveCertifications: [
            "Leadership Skills",
            "Software",
            "Infection",
            "Equity and Inclusion",
            "Counterfeit Detection",
            "DECA/Junior Achievement",
            "Public Speaking",
            "Negotiation Training",
            "Personality Inventory"
        ],
        reachAheadandExperientialLearning: [
            "Post-Secondary Info Evening",
            "Career Fairs",
            "Business Student For A Day (George Brown)",
            "Schulich Visit",
            "Industry Tours",
            "Rotman Case Study",
            "Skills Competitions"
        ],
        sectorPartneredEvents: [
            "Coding",
            "ICE Training",
            "Mathematical Literacy"
        ],
        coopPlacements: [
            "Accountant",
            "Human Resources Assistant",
            "Retail Associate",
            "Customer Relations Representative",
            "Media Relations Assistant",
            "Bookkeeper",
            "Office Administrative Assistant",
            "Theatre Admin Assistant",
            "Marketing Assistant",
            "Law/Paralegal Assistant"
        ],
    },
    {
        name: "HealthandWellness",
        title: "Health and Wellness",
        introduction: "Are you amazed by the human body? Do you strive to live a healthy lifestyle? If so, Health and Wellness is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of healthy experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
        description: "Are you interested in anything related to health? How the human body works? Or how to live a healthy lifestyle? If so, Health and Wellness is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of healthy experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
        insights: [
            "Explore a variety of future career pathways and education in medicare",
            "Gain valuable insight from experts and students and their personal experiences",
            "Participate in events providing relevant and accessible learning opportunities"
        ],
        majorCredits: [
            "HFA4C/4U (Nutrition and Health)",
            "HPW3C (Working with Children)",
            "PAI4O (Badminton)",
            "PPL3O/4O (Phys Ed)",
            "SNC4M (Health Sciences)",
            "HFC3M (Food & Culture)",
            "HSE4M (Equity & Social Justice)",
            "PAL3O/4O (Volleyball)",
            "PSK4U (Exercise Science)",
            "SPH3U/4C/4U (Physics)",
            "HHS4C/4U (Families in Canada)",
            "HSP3C/3U (Anthro/Psych/Soc)",
            "PAD3O/4O (Outdoor Ed)",
            "SCH3U/4C/4U (Chemistry)",
            "TXJ3E (Hairstyling)",
            "HPC3O (Parenting)",
            "PAF3O/4O (Fitness)",
            "PLF4M (Leadership)",
            "SBI3C/3U/4U (Biology)"
        ],
        englishCredits: [
            "ENG3C/E/U (English 11)",
            "ENG4C/E/U (English 12)",
            "OLC4O (Literacy)"
        ],
        relatedMathCredits: [
            "MBF3C (Foundations for College Mathematics)",
            "MCT4C (Mathematics for College Technology)",
            "MCF3M (Functions and Applications)",
            "MDM4U (Data Management)",
            "MCR3U (Functions)",
            "MEL3E/4E (Mathematics for Work and Everyday Life)",
            "MAP4C (Foundations for College Mathematics)",
            "MHF4U (Advanced Functions)"
        ],
        relatedCredits: [
            "BDI3C (Entrepreneurship)",
            "HPC3O (Parenting)",
            "HZB3M/HZT4U (Philosophy)",
            "SPH3U/4C/4U (Physics)",
            "ICS3C/3U/4C/4U (Computer Science)",
            "HFA4U (Nutrition and Health)",
            "HPW3C (Working with Children)",
            "PSK4U (Exercise Science)",
            "SVN3E/3M (Environmental Science)",
            "OLC4O (Literacy)",
            "HFC3M (Food and Culture)",
            "HSB4U (Challenge & Change)",
            "SBI3C/3U (Biology)",
            "SES4U (Earth and Space Science)",
            "MEL3E/4E (Mathematics)",
            "HHS4U (Families in Canada)",
            "HSP3C/3U (Anthro/Psych/Soc)",
            "SCH3U/4C/4U (Chemistry)",
            "GLE4O/GLS4O (Learning Strategies)"
        ],
        compulsoryCertifications: [
            "Standard First Aid & CPR/AED Level C",
            "WHMIS",
            "Customer Service"
        ],
        electiveCertifications: [
            "Customer Service",
            "Personal Inventory",
            "Safe Food Handling",
            "Conflict Resolution",
            "Sterile Techniques",
            "Suicide Prevention",
            "SAFETALK",
            "Wrapping and Taping for Performance & Injury"
        ],
        reachAheadandExperientialLearning: [
            "University/College Workshops",
            "Guest Speakers",
            "Career Fairs",
            "Skills Competitions",
            "Industry Tours",
            "P.A.R.T.Y. Program"
        ],
        sectorPartneredEvents: [
            "Coding",
            "ICE Training",
            "Mathematical Literacy"
        ],
        coopPlacements: [
            "Biomedical Engineering",
            "Dentist",
            "Education Assistant",
            "Child & Youth Worker",
            "Hairstylist",
            "Medical Secretary",
            "Early Childhood Education",
            "Physiotherapist",
            "Personal Support Worker",
            "Nursing",
            "Child and Youth Workers",
            "Veterinary Medicine",
            "Pharmacist"
        ],
    },
    {
        name: "ICT",
        title: "Information and Communications Technology",
        introduction: "ICT is a very cool sector. We have tons of intriguing events whether it be playing with robots, participating in hackathons or listening in on a university panel, that you wouldn't want to miss out on!!",
        description: "Interested in technology? Do you love programming, engineering, graphic design, or data analysis? If that sounds familiar, welcome to the Information and Communications Technology sector! Here, you’ll be able to participate in a wide array of interesting technological opportunities both in and out of school.",
        insights: [
            "Implement your knowledge to participate in real-world technological advancements",
            "Enhance your problem-solving and thinking skills through challenging problems",
            "Connect with professional programmers, engineers, designers, and more!"
        ],
        majorCredits: [
            "ICS3U/3C/4U/4C (Computer Science)",
            "TDJ4M (Mechanical and Industrial Design)",
            "SPH3U/4U/4C (Physics)",
            "TEJ4R (Robotics and Control Systems)",
            "TDJ3O/3M/4E/4M (Technological Design)",
            "TEJ3E/3M/4E/4M (Computer Engineering Technology)",
            "TGJ3M/4M (Communications Technology)"
        ],
        englishCredits: [
            "ENG3C/E/U (English 11)",
            "ENG4C/E/U (English 12)",
            "OLC4O (Literacy)"
        ],
        relatedMathCredits: [
            "MBF3C (Foundations for College Mathematics)",
            "MCT4C (Mathematics for College Technology)",
            "MCF3M (Functions and Applications)",
            "MDM4U (Data Management)",
            "MCR3U (Functions)",
            "MEL3E/4E (Mathematics for Work and Everyday Life)",
            "MAP4C (Foundations for College Mathematics)",
            "MHF4U (Advanced Functions)"
        ],
        relatedCredits: [
            "BAF3M/BAT4M (Accounting)",
            "SCH3U/4C/4U (Chemistry)",
            "BMI3C/BMX3E (Marketing)",
            "SPH4C/4U (Physics)",
            "CLU3M/CLN4U (Law)",
            "BBB4M (International Business)",
            "SVN3E/SNC4E (Environmental Science)",
            "BOG4E/4M (Business Leadership)"
        ],
        compulsoryCertifications: [
            "Standard First Aid & CPR/AED Level C",
            "WHMIS",
            "Customer Service"
        ],
        electiveCertifications: [
            "Advanced Training in a Technique",
            "Customer Service",
            "Lighting and Sound Equipment Maintenance",
            "Basic Electrical Safety",
            "Infection Control",
            "Technical Support",
            "Computer Hardware",
            "Internet Security",
            "Ergonomics"
        ],
        reachAheadandExperientialLearning: [
            "University/College Workshops",
            "Guest Speakers",
            "OUIT Internet Security and Video Gaming Labs Tour",
            "Industry Tours",
            "Trent University: Media Studies Program",
            "Career Fairs",
            "Skills Competitions"
        ],
        sectorPartneredEvents: [
            "Coding",
            "ICE Training",
            "Mathematical Literacy"
        ],
        coopPlacements: [
            "Analyst Programmer",
            "Computer Network Technician",
            "Motion Graphics Designer",
            "Web Developer",
            "Audio Video Technician",
            "Film and Video Camera Operator",
            "Software Engineer",
            "Computer Engineer",
            "Graphic Designer",
            "Telecommunications Technician"
        ],
    }
];

export default Data;