import React, {useCallback, useEffect} from 'react';
import video from '../videos/shsmvideo.mp4';
import shsm_art from '../img/shsm_art.webp';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from "@material-tailwind/react";
import {NavLink} from "react-router-dom";
import Data from "../components/export_text/Data";
import {TeamsComponent} from "./Team";

export default function Home(props) {
    const scrollEvent = useCallback(() => {
        const section2 = document.getElementById("section_2");
        props.setNav(
            !(window.pageYOffset >= section2.offsetTop || document.scrollTop >= section2.offsetTop)
        )
    }, [props]);

    useEffect(() => {
        const location = window.location.pathname;
        if (location === '/')
            document.addEventListener('scroll', scrollEvent);
        return () => {
            document.removeEventListener('scroll', scrollEvent);
        };
    }, [scrollEvent]);
    return (
        <>
            <section className="relative gradient md:h-screen min-h-[30vh]" id="section_1">
                <video autoPlay={true} loop={true} muted={true} playsInline={true}
                       className="left-[50%] translate-x-[-50%] absolute h-full"
                       poster=""
                >
                    <source src={video} type="video/mp4" width={900}/>
                    Your browser does not support the video tag.
                </video>

                <div
                    className="absolute m-0 top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] mx-auto z-10">
                    <h2 className="text-white text-center md:text-4xl text-2xl">Welcome to</h2><h1
                    className={"text-[#FFD700] text-center md:text-8xl font-bold text-3xl"}>SHSM PETHS</h1>
                </div>
            </section>


            <section className="row py-12 px-20 lg:columns-2" id="section_2">
                <div className="flex flex-col">
                    <h2 className="text-xxl my-4">About <b className="text-red-500">Us</b></h2>
                    <p> SHSM is short for Specialist High Skills Majors.
                        Through a co-op program tailored to your interests and skills, various learning workshops, and
                        mentorship events, the SHSM program will help place you on your career path and confirm if it is
                        the
                        right fit for you.
                    </p>
                    <p className={"my-2"}>
                        What's more is that upon completion of the requirements, you’ll earn a special SHSM seal on your
                        Ontario Secondary School Diploma - something that really stands out to potential employers!
                    </p>
                    <div className="flex row my-2">
                        <NavLink
                            className="py-2 rounded-3xl border-2 border-yellow-300 px-5 font-bold hover:text-gray-400 text-yellow-300 hover:bg-yellow-300 hover:border-transparent"
                            onClick={() => document.getElementById("section_4").scrollIntoView()}
                            to={"#section_4"} children={"Check out our team"}/>
                        <NavLink
                            className={"flex items-center py-2 rounded-3xl bg-red-600 mx-5 px-5 font-bold text-white hover:bg-yellow-300 hover:text-red-500"}
                            to={"/join"} children={"Join Today"}/>
                    </div>
                </div>
                <img src={shsm_art} width={400} height={400} alt="shsm_art"
                     className="hidden md:block mx-auto h-full"/>
            </section>


            <section className="section py-12 lg:px-20 px-5 bg-[#1b1f20]" id="section_3">
                <h2 className="mb-4 text-red-500 text-center lg:text-left">Sectors</h2>
                <div className="align-items-start">
                    <Tabs value={Data[0].name} orientation="vertical">
                        <TabsHeader className="mx-5 bg-transparent bg-black" indicatorProps={{
                            className: "bg-gray-400/30 shadow-none !text-yellow-500 hover:cursor-initial"
                        }}>
                            {
                                Data.map((object) => (
                                    <Tab key={object.name} value={object.name} activeClassName={object.active}
                                         className={"rounded-lg font-lg py-2 cursor-pointer"}>
                                        {object.title}
                                    </Tab>
                                ))
                            }
                        </TabsHeader>
                        <TabsBody
                            animate={{
                                initial: {y: 250},
                                mount: {y: 0},
                                unmount: {y: 250},
                            }}>
                            {
                                Data.map((object) => (
                                    <TabPanel key={object.name} value={object.name} className="py-0">
                                        {object.description}
                                    </TabPanel>
                                ))
                            }
                        </TabsBody>
                    </Tabs>
                </div>
            </section>


            <section className="py-12 px-20 section w-full" id="section_4">
                <div className="row w-full">
                    <h2 className="mb-4">Meet the <b className="text-red-500">Team</b></h2>
                    <p className="main-team-text"> SHSM at PETHS is a group of hardworking individuals passionate
                        about
                        helping students discover and reach their career aspirations.
                        You can count on us to ensure you will leave with a more thorough understanding of your
                        intended
                        field and successfully acquire the SHSM seal.
                    </p>

                    <TeamsComponent/>
                </div>
            </section>


            {/*
            https://www.emailjs.com/
            <section className="row mx-52 bg-gray-900 px-40 py-5 my-10 rounded-2xl" id="section_6">
                <form method="POST" name="mail">
                    <h2>Contact <b className={"text-red-500"}>Us</b></h2>
                    <div className="mb-4 flex flex-col py-5 gap-1">
                        <Input size="lg" variant={"outlined"} placeholder={"Name"}
                               className={"bg-gray-600 rounded-2xl px-2 py-3 border border-black"}/>
                        <Input size="lg" variant={"outlined"} placeholder="Subject"
                               className={"bg-gray-600 rounded-2xl px-2 py-3 border border-black"}/>
                        <Input size="lg" variant={"outlined"} placeholder="Email"
                               className={"bg-gray-600 rounded-2xl px-2 py-3 border border-black"}/>
                        <Textarea variant={"outlined"} size="lg" placeholder="Content"
                                  row={20}
                                  className={"bg-gray-600 rounded-2xl px-2 border border-black"}/>
                        <Button className={"mt-6 bg-gray-950"} onClick={() => {
                        }} fullWidth children={"Submit"}/>
                    </div>
                </form>
            </section>
            */}
        </>
    );
}