import {useState} from 'react';
import {Disclosure} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {NavLink, useLocation} from 'react-router-dom';
import logo from '../img/shsmlogo.webp'

const navigation = [
    {name: 'Home', href: '/'},
    {name: 'Join Us', href: '/join'},
    {
        name: 'Sectors',
        children:
            [
                {
                    name: 'Arts and Cultures',
                    href: '/artsandcultures',
                },
                {
                    name: "Business",
                    href: '/business',
                },
                {
                    name: 'Health and Wellness',
                    href: '/healthandwellness',
                },
                {
                    name: 'ICT',
                    href: '/ict'
                }
            ]
    },
    {name: 'Our Team', href: '/team'},
    {name: 'Events', href: '/events'},
    {
        name: 'SHSM Website',
        href: 'https://www2.yrdsb.ca/schools-programs/school-programs-nav/school-programs/specialist-high-skills-major',
        target: "_blank"
    },
]

export default function Nav() {
    const location = useLocation();
    const [hover, setHover] = useState('');
    const [mobile, setMobile] = useState(false);
    return (
        <Disclosure as="nav" id={"nav"}
                    className={`p-2 top-2 mb-4 bg-red-800 w-[90%] z-50 rounded-3xl
                    ${location.pathname === "/" ? "lg:fixed lg:left-[50%] lg:translate-x-[-50%] sticky mx-auto" : "sticky mx-auto"}`}>
            {({open}) => (
                <>
                    <div className="lg:mx-7 mx-4 relative flex h-20 items-center justify-between">
                        {/* Mobile menu button */}
                        <Disclosure.Button
                            className="absolute inset-y-0 left-0 items-center sm:hidden inline-flex justify-center rounded-md p-2">
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XMarkIcon width={35} height={35} aria-hidden="true"/>
                            ) : (
                                <Bars3Icon width={35} height={35} aria-hidden="true"/>
                            )}
                        </Disclosure.Button>
                        <div
                            className="flex flex-1 h-full items-center justify-center sm:items-stretch sm:justify-between">
                            <div className="flex flex-shrink-0 items-center">
                                <img src={logo} alt={"logo"} className={"w-auto px-2"} width={60} height={60}/>
                                <p className={"text-yellow-400 font-bold text-xl leading-none"}>SHSM<br/>PETHS</p>
                            </div>

                            {/*Desktop Panel*/}
                            <div className="md:flex flex-row hidden self-center space-x-4 h-full">
                                {navigation.map((item) => (
                                    item.children != null ?
                                        <div
                                            key={item.name}
                                            className={
                                                `relative h-full top-0 left-0 rounded-md px-2 text-md font-medium flex items-center text-gray-300 hover:text-yellow-400'}`
                                            }
                                            aria-current={item.current ? 'page' : undefined}
                                            onMouseEnter={() => setHover(item.name)}
                                            onMouseLeave={() => setHover('')}
                                        >
                                            {item.name}
                                            {
                                                hover === item.name &&
                                                <div
                                                    className="absolute bg-red-700 flex flex-col left-[50%] translate-x-[-50%] top-[75%] min-w-[200px] items-center px-2 py-1 text-md font-medium text-gray-400 rounded-md"
                                                    aria-expanded={false}>
                                                    {
                                                        item.children.map((child) => (
                                                            <NavLink
                                                                key={child.href.replace("/", "")}
                                                                to={child.href}
                                                                className={({isActive}) => {
                                                                    return `rounded-md px-2 py-1 text-md font-medium ${isActive ? 'text-yellow-400 font-black hover:cursor-default' : 'text-gray-300 hover:text-yellow-400'}`
                                                                }}
                                                                target={item.target}
                                                                aria-current={item.current ? 'page' : undefined}
                                                            >
                                                                {child.name}
                                                            </NavLink>
                                                        ))
                                                    }
                                                </div>

                                            }
                                        </div> :
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={({isActive}) => {
                                                return `relative h-full top-0 left-0 rounded-md px-2 text-md font-medium flex items-center ${!isActive ? 'text-gray-300 hover:text-yellow-400' : `text-yellow-400 font-black hover:cursor-default`}`
                                            }}
                                            target={item.target}
                                            aria-current={item.current ? 'page' : undefined}
                                            onMouseEnter={() => setHover(item.name)}
                                            onMouseLeave={() => setHover('')}
                                        >
                                            {item.name}
                                        </NavLink>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/*Mobile Panel*/}
                    <Disclosure.Panel className="sm:hidden md:space-y-1 px-2 pt-2 pb-3 relative text-white">
                        {navigation.map((item) => (
                            item.children != null ?
                                <div
                                    key={item.name}
                                    className={
                                        `px-2 py-2 text-md font-medium`
                                    }
                                    onClick={() => setMobile(!mobile)}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                    {
                                        mobile &&
                                        <div
                                            className="flex flex-col px-3 text-md font-medium"
                                            aria-expanded={false}>
                                            {
                                                item.children.map((child) => (
                                                    <Disclosure.Button
                                                        key={child.name}
                                                        as="a"
                                                    >
                                                        <NavLink
                                                            to={child.href}
                                                            className={({isActive}) => {
                                                                return `py-1 text-md font-medium flex ${!isActive ? '' : `text-yellow-400 font-black`}`
                                                            }}
                                                            target={item.target}
                                                            aria-current={item.current ? 'page' : undefined}
                                                        >
                                                            {child.name}
                                                        </NavLink>
                                                    </Disclosure.Button>
                                                ))
                                            }
                                        </div>
                                    }
                                </div> :
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                >
                                    <NavLink
                                        to={item.href}
                                        className={({isActive}) => {
                                            return `flex px-2 py-2 text-md font-medium ${!isActive ? '' : `text-yellow-400 font-black`}`
                                        }}
                                        target={item.target}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </NavLink>
                                </Disclosure.Button>
                        ))}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}