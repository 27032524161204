import sophia23 from './2023/sophia.png';
import fiona23 from './2023/fiona.png';
import diya23 from './2023/diya.png';
import bryson23 from './2023/bryson.png';
import winnie23 from './2023/winnie.png';
import tiffany231 from './2023/tiffany.png';
import ryan23 from './2023/ryan.png';
import carolyn23 from './2023/carolyn.png';
import jessica23 from './2023/jessica.png';
import catherine23 from './2023/catherine.png';
import gloriana23 from './2023/gloriana.png';
import tiffany232 from './2023/tiffany2.png';
import elaine23 from './2023/elaine.png';
import alice22 from './2022/alice.webp';
import victoria22 from './2022/victoria.webp';
import janelle22 from './2022/janelle.webp';
import nikki22 from './2022/nikki.webp';
import sonia22 from './2022/sonia.webp';
import aryan22 from './2022/aryan.webp';
import matthew22 from './2022/matthew.webp';
import kendra22 from './2022/kendra.webp';
import elaine22 from './2022/elaine.webp';
import tiffany22 from './2022/tiffany.webp';
import rachel22 from './2022/rachel.webp';
import sophia22 from './2022/sophia.webp';
import placeholder from '../placeholder.webp';

export const team = {
    keys: [2023, 2022, 2021],
    2023: [
        {
            name: "Elaine",
            role: "Co-Chair",
            img: elaine23,
            link: "https://www.instagram.com/elaiine.lin/"
        },
        {
            name: "Sophia",
            role: "Co-Chair",
            img: sophia23,
            link: "https://www.instagram.com/s8phias/"
        },
        {
            name: "Fiona",
            role: "Secretary",
            img: fiona23,
            link: "https://www.instagram.com/feeohna.a"

        },
        {
            name: "Diya",
            role: "Media Pub",
            img: diya23,
            link: "https://www.instagram.com/diya_dhandha"
        },
        {
            name: "Bryson",
            role: "Tech Chair",
            img: bryson23,
            link: "https://www.instagram.com/brysonlk23/"
        },
        {
            name: "Winnie",
            role: "Arts and Culture",
            img: winnie23,
            link: "https://www.instagram.com/wnniewang"
        },
        {
            name: "Tiffany",
            role: "Arts and Culture",
            img: tiffany231,
            link: "https://www.instagram.com/t.offany"
        },
        {
            name: "Ryan",
            role: "Business",
            img: ryan23,
            link: "https://www.instagram.com/ryanwkwok"
        },
        {
            name: "Carolyn",
            role: "Business",
            img: carolyn23,
            link: "https://www.instagram.com/carolyn_0121"
        },
        {
            name: "Jessica",
            role: "Health and Wellness",
            img: jessica23,
            link: "https://www.instagram.com/jessicaa_kong"
        },
        {
            name: "Catherine",
            role: "Health and Wellness",
            img: catherine23,
            link: "https://www.instagram.com/cxtherineli"
        },
        {
            name: "Gloriana",
            role: "ICT",
            img: gloriana23,
            link: "https://www.instagram.com/gloriana.zheng"
        },
        {
            name: "Tiffany",
            role: "ICT",
            img: tiffany232,
            link: "https://www.instagram.com/tiiffanyho"
        },
        {
            name: "Ms. Sacranie",
            role: "Teacher Supervisor",
            img: placeholder,
        },
        {
            name: "Ms. Syed",
            role: "Teacher Supervisor",
            img: placeholder,
        }
    ],
    2022: [
        {
            name: "Alice",
            role: "Co-President",
            img: alice22,
            link: "https://www.instagram.com/ali.ccem/"
        },
        {
            name: "Victoria",
            role: "Co-President",
            img: victoria22,
            link: "https://www.instagram.com/_victoria.do_/"
        },
        {
            name: "Janelle",
            role: "Secretary",
            img: janelle22,
            link: "https://www.instagram.com/janelleyy.beann/"
        },
        {
            name: "Nikki",
            role: "Equity Chair",
            img: nikki22,
            link: "https://www.instagram.com/nikkiii_44/"
        },
        {
            name: "Sonia",
            role: "Arts and Culture Chair",
            img: sonia22,
            link: "https://www.instagram.com/soniaa._da/"
        },
        {
            name: "Aryan",
            role: "Arts and Culture Chair",
            img: aryan22,
            link: "https://www.instagram.com/aryan_rajagopal/"
        },
        {
            name: "Matthew",
            role: "Business Chair",
            img: matthew22,
            link: "https://www.instagram.com/matthew.wn_/"
        },
        {
            name: "Kendra",
            role: "Business Chair",
            img: kendra22,
            link: "https://www.instagram.com/kendra_guna/"
        },
        {
            name: "Elaine",
            role: "Health and Wellness Chair",
            img: elaine22,
            link: "https://www.instagram.com/elaiine.lin/"
        },
        {
            name: "Tiffany",
            role: "Health and Wellness Chair",
            img: tiffany22,
            link: "https://www.instagram.com/tiffxny.luii/"
        },
        {
            name: "Rachel",
            role: "ICT Chair",
            img: rachel22,
            link: "https://www.instagram.com/rubychaels/"
        },
        {
            name: "Sophia",
            role: "ICT Chair",
            img: sophia22,
            link: "https://www.instagram.com/s8phias/"
        },
        {
            name: "Ms. Yuen",
            role: "Teacher Advisor",
            img: placeholder
        },
        {
            name: "Ms. Syed",
            role: "Teacher Advisor",
            img: placeholder
        },
        {
            name: "Peterson",
            role: "Tech Chair",
            img: placeholder,
            link: "https://www.instagram.com/petersonguo/"
        }
    ],
    2021: []
};

export default team;