import React from "react";
import team from "../img/team/export_team";
import {BsInstagram} from "react-icons/bs";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";

export function TeamsComponent() {
    const [index, setIndex] = React.useState(0);
    return (
        <>
            <div className={"flex flex-row items-center justify-center py-4 text-yellow-400"}>
                <HiChevronLeft size={40} className={index > 0 ? "hover:cursor-pointer" : "text-gray-500"}
                               onClick={() => index > 0 ? setIndex(index - 1) : null}/>
                <p className={"font-bold text-2xl"}>{team.keys[index]}</p>
                <HiChevronRight size={40}
                                className={index < team.keys.length - 1 ? "hover:cursor-pointer" : "text-gray-500"}
                                onClick={() => index < team.keys.length - 1 ? setIndex(index + 1) : null}/>
            </div>
            <div className={"flex justify-center flex-wrap items-stretch gap-5"}>
                {
                    team[team.keys[index]].map((member, i) => (
                        <div key={"card_" + i} className={"flex flex-col items-center h-full"}>
                            <img src={member.img} alt={"photo of " + member.name}
                                 className={"w-40 h-40 rounded-full"} width={160} height={160}/>
                            <h2 className={"text-xl font-bold"}>{member.name}</h2>
                            <h3 className={"text-lg"}>{member.role}</h3>
                            {
                                member.link != null &&
                                <BsInstagram size={30}
                                             className={"my-2 hover:cursor-pointer hover:text-yellow-300"}
                                             alt={"instagram"}
                                             onClick={() =>
                                                 window.open(member.link, "_blank")
                                             }/>
                            }
                        </div>
                    ))}
            </div>
        </>
    );
}

export default class Team extends React.Component {
    render() {
        return (
            <div className={"flex flex-col items-center lg:py-20 py-5 px-20"}>
                <h1 className={"text-5xl font-bold text-center my-2"}>Our <b className={"text-red-700"}>Team</b></h1>
                <TeamsComponent/>
            </div>
        );
    }
}