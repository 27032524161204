import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Nav from './components/Nav';
import Home from './pages/Home';
import Join from './pages/Join';
import Team from './pages/Team';
import Events from './pages/Events';
import NoPage from './pages/NoPage';
import Footer from "./components/Footer";
import Sectors from "./pages/Sectors";
import Data from "./components/export_text/Data";
import DescriptionBox from "./components/export_text/Blurbs";
import {HiOutlineArrowCircleUp} from "react-icons/hi";

export default function App() {
    const [nav, setNav] = React.useState(true);

    return (
        <Router>
            {nav && <Nav/>}
            <Routes>
                <Route index element={<Home nav={nav} setNav={setNav}/>}/>;
                <Route path={'/join'} element={<Join blurb={<DescriptionBox name={"join"}/>}/>}/>
                <Route path={'/artsandcultures'}
                       element={<Sectors blurb={<DescriptionBox name={"artsAndCultures"}/>} child={Data[0]}/>}/>
                <Route path={'/business'}
                       element={<Sectors blurb={<DescriptionBox name={"business"}/>} child={Data[1]}/>}/>
                <Route path={'/healthandwellness'}
                       element={<Sectors blurb={<DescriptionBox name={"healthAndWellness"}/>} child={Data[2]}/>}/>
                <Route path={'/ict'} element={<Sectors blurb={<DescriptionBox name={"ict"}/>} child={Data[3]}/>}/>
                <Route path={'/team'} element={<Team/>}/>
                <Route path={'/events'} element={<Events/>}/>
                <Route path="*" element={<NoPage/>}/>
            </Routes>
            {
                !nav &&
                <HiOutlineArrowCircleUp size={40}
                                        className={"fixed bottom-5 right-[5%] hover:cursor-pointer hover:text-yellow-400"}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}/>
            }
            <Footer/>
        </Router>
    );
}