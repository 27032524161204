import React from "react";

export default class DescriptionBox extends React.Component {
    state = {
        joinBlurb: {
            title: "About SHSM",
            description: "Specialist High Skills Majors (SHSM) is for motivated individuals like you. Through this program, you’ll be given the opportunity to focus on the career path that matches your skills and interests. While working towards your Ontario Secondary School Diploma (OSSD), you’ll also be able to earn the SHSM seal on your diploma by meeting the following requirements:",
            list: [
                "Complete a specific bundle of 8-10 courses in your selected field.",
                "Earn valuable industry certifications such as First Aid and CPR qualifications.",
                "Gain important skills on the job through cooperative education placements."
            ]
        },
        artsAndCulturesBlurb: {
            title: "About the Sector",
            description: "Are you an avid creator? Are you an individual who enjoys visual arts, literary arts, or the performing arts? If so, Arts and Culture is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of artistic experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
            list: [
                "Develop specialized skills through workshops and events with guest speakers",
                "Participate in a multitude of opportunities and network within the business sector",
                "Gain important skills on the job through cooperative education placements"
            ]
        },
        businessBlurb: {
            title: "About the Sector",
            description: "Do you enjoy understanding how money works? How businesses are created, run, and managed? If so, Business is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of business-y experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
            list: [
                "Gain insight on a variety of business-related career paths through engaging activities",
                "Develop skills not offered at school that can be used in real-world situations",
                "Connect with entrepreneurs and industry leaders in a variety of niches"
            ]
        },
        healthAndWellnessBlurb: {
            title: "About the Sector",
            description: "Are you interested in anything related to health? How the human body works? Or how to live a healthy lifestyle? If so, Health and Wellness is the sector for you! Through this sector, you’ll be able to take advantage of a wide array of healthy experiences, from interesting courses at school, to wonderful co-op placements throughout our community!",
            list: [
                "Explore a variety of future career pathways and education in medicare",
                "Gain valuable insight from experts and students and their personal experiences",
                "Participate in events providing relevant and accessible learning opportunities"
            ]
        },
        ictBlurb: {
            title: "About the Sector",
            description: "Interested in technology? Do you love programming, engineering, graphic design, or data analysis? If that sounds familiar, welcome to the Information and Communications Technology sector! Here, you’ll be able to participate in a wide array of interesting technological opportunities both in and out of school.",
            list: [
                "Implement your knowledge to participate in real-world technological advancements",
                "Enhance your problem-solving and thinking skills through challenging problems",
                "Connect with professional programmers, engineers, designers, and more!"
            ]
        }
    };

    render() {
        return (
            <div className="bg-[#1b1f20] my-2 rounded-3xl md:px-16 py-9 px-8">
                <h2 className={"text-3xl font-bold"}>{this.state[this.props.name + "Blurb"].title}</h2>
                <p className={"py-5"}>{this.state[this.props.name + "Blurb"].description}</p>
                <div className="lg:flex flex-row lg:columns-3 gap-10 space-y-3 my-3">
                    {
                        this.state[this.props.name + "Blurb"].list.map((str, i) => {
                            return (
                                <p className={"py-8 md:px-12 px-8 bg-[#181a1b] rounded-3xl"}
                                   key={"blurb_string_" + i}>{str}</p>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}