import React from 'react';
import {Link} from 'react-router-dom';
import {BsEnvelope, BsInstagram, BsLinkedin} from "react-icons/bs";
import {ReactComponent as ClassroomIcon} from "../img/classroom.svg";
import logo from '../img/shsmlogo.webp';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="row align-items-center bg-[#1b1f20] px-[10%] py-5 text-white" id={"footer"}>
                <div className="flex row">
                    <Link to="/" className="row flex align-center navbar-brand columns-2 gap-2">
                        <img src={logo} width={60} height={60} alt="logo" aria-label={"logo"}/>
                        <span
                            className="text-yellow-400 font-bold text-lg brand-text flex flex-col hover:text-red-600"><p>SHSM</p><p>PETHS</p></span>
                    </Link>
                    <div className="ms-auto flex row my-auto">
                        <Link to="https://classroom.google.com/c/NDMyOTgwNjcw?cjc=bb7sirw" target={"_blank"}
                              className="px-1 hover:cursor-pointer h-9 w-9"
                              aria-label={"google-classroom"}
                        >
                            <ClassroomIcon size={25} className={"classroom-icon"} alt={"google-classroom"}/>
                        </Link>
                        <Link to="https://www.instagram.com/shsm.peths/?hl=en" target={"_blank"}
                              className="px-1 hover:text-yellow-400"
                              aria-label={"instagram"}
                        >
                            <BsInstagram size={25} alt={"instagram"}/>
                        </Link>
                        <Link to="https://www.linkedin.com/company/shsm-trudeau/about/" target={"_blank"}
                              className="px-1 hover:text-yellow-400"
                              aria-label={"linkedin"}
                        >
                            <BsLinkedin size={25} alt={"linkedin"}/>
                        </Link>
                    </div>
                </div>

                <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"/>

                <div className="md:flex row justify-between">
                    <div>
                        <h5 className={"text-xl font-bold"}>SHSM PETHS</h5>
                        <Link to="mailto:shsm.peths@gmail.com"
                              target="_blank" className={"row flex gap-2 hover:text-yellow-400"}
                              aria-label={"shsm.peths@gmail.com"}
                        >
                            <BsEnvelope size={20} alt={"mail"}/>
                            shsm.peths@gmail.com
                        </Link>
                    </div>


                    <div className="ms-lg-auto flex align-items-center row">
                        <p className="ms-lg-auto me-4 mb-0">Pierre Elliott Trudeau SHSM<br/>Design: Peterson Guo</p>
                    </div>
                </div>
            </div>
        );
    }
}